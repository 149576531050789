<!--=========================================================================================
  File Name: GameScreen.vue
  Description: Game Page
==========================================================================================-->
<template>
<div class="content gamescreen-page">
		<game-header />
		<div class="row betting-area" style="padding-bottom:5px;">
			<div class="col-12 rm-side-padding">
				<betting-area :bet="bet" @playAudio="playAudio" :gameType = 'gameType'/>
			</div>
		</div>
	<!-- Game Result -->
	<!-- <game-result v-if="currentRoom.state == 3 && cardResult.length > 0 && !hideCard" :playerCard="cardResult[0]" :bankerCard="cardResult[1]" :gameType="gameType"/> -->
	<game-result  :playerCard="cardResult[0]" :bankerCard="cardResult[1]" :gameType="gameType"/>


	<!-- <game-result v-if="currentRoom.state == 3 && cardResult && !hideCard" :playerCard="cardResult[0]" :bankerCard="cardResult[1]" /> -->
	<shoe-area :gameType = 'gameType'/>
</div>

</template>

<script>

import GameHeader from '@/components/common/mobile/GameHeader'
import BettingArea from '@/components/baccarat/game/mobile/BettingArea'
import ShoeArea from '@/components/baccarat/game/mobile/ShoeArea'
import GameResult from '@/components/baccarat/game/mobile/GameResult'

export default {
    name: 'GameScreen',
    components: {
		BettingArea, ShoeArea,GameResult, GameHeader
	},
    created() {
		this.$store.dispatch('gameModule/checkIfLoggedIn')
		window.addEventListener("resize", this.getBottomHeight);
	},
	mounted() {
		this.playAudio('welcome');
		this.$store.commit("gameModule/SET_ROOM_DATA", {
        roomno: String(this.$route.params.roomno),
        roomorder: "",
        state: 2,
        shoe: "",
        time: 0,
        result: null,
      });

      this.$socket.client.emit("switch_room", {
        roomno: String(this.$route.params.roomno),
        level: this.$store.state.gameModule.currentLevel,
        mobile: false,
      });
    },
    destroyed() {
        window.removeEventListener("resize", this.getBottomHeight);
	},
    data() {
        return {
			gameType: '',
			brTblHeight: { },
			berTblHeight: { },
			shoeCntHeight: { },
			shoeCntRes: { },
			isBetReset: true,
			timer: 60,
			bet: {
				player: 0,
				playerPair: 0,
				banker: 0,
				bankerPair: 0,
				tie: 0
			},
			totalBet: {
				player: 0,
				playerPair: 0,
				banker: 0,
				bankerPair: 0,
				tie: 0
			},
			hideCard: true,
			audioFiles: [
				{
					id: 'welcome',
					file: new Audio('/assets/sounds/ko/welcome.mp3'),
					isPlaying: false,
					canOverlap: false
				},
				{
					id: 'banker-win',
					file: new Audio('/assets/sounds/ko/banker_win.mp3'),
					isPlaying: false,
					canOverlap: false
				},
				{
					id: 'player-win',
					file: new Audio('/assets/sounds/ko/player_win.mp3'),
					isPlaying: false,
					canOverlap: false
				},
				{
					id: 'tie-win',
					file: new Audio('/assets/sounds/ko/tie_game.mp3'),
					isPlaying: false,
					canOverlap: false
				},
				{
					id: 'countdown',
					file: new Audio('/assets/sounds/ko/countdown.mp3'),
					isPlaying: false,
					canOverlap: true
				},
				{
					id: 'countdown2',
					file: new Audio('/assets/sounds/ko/countdown.mp3'),
					isPlaying: false,
					canOverlap: true
				},
				{
					id: '5',
					file: new Audio('/assets/sounds/ko/5.mp3'),
					isPlaying: false,
					canOverlap: true
				},
				{
					id: '4',
					file: new Audio('/assets/sounds/ko/4.mp3'),
					isPlaying: false,
					canOverlap: true
				},
				{
					id: '3',
					file: new Audio('/assets/sounds/ko/3.mp3'),
					isPlaying: false,
					canOverlap: true
				},
				{
					id: '2',
					file: new Audio('/assets/sounds/ko/2.mp3'),
					isPlaying: false,
					canOverlap: true
				},
				{
					id: '1',
					file: new Audio('/assets/sounds/ko/1.mp3'),
					isPlaying: false,
					canOverlap: true
				},
				{
					id: 'chip',
					file: new Audio('/assets/sounds/general/chip.mp3'),
					isPlaying: false,
					canOverlap: true
				},
				{
					id: 'chip2',
					file: new Audio('/assets/sounds/general/chip.mp3'),
					isPlaying: false,
					canOverlap: true
				},
				{
					id: 'betting-start',
					file: new Audio('/assets/sounds/ko/betting_start.mp3'),
					isPlaying: false,
					canOverlap: false
				},
				{
					id: 'betting-end',
					file: new Audio('/assets/sounds/ko/betting_end.mp3'),
					isPlaying: false,
					canOverlap: false
				},
				{
					id: 'betting-successful',
					file: new Audio('/assets/sounds/ko/betting_ok.mp3'),
					isPlaying: false,
					canOverlap: true
				},
				{
					id: 'bgm',
					file: new Audio('/assets/sounds/ko/bgm1.mp3'),
					isPlaying: false,
					canOverlap: false
				}
			]
		}
	},
	computed: {

		limit() {
			return this.$store.getters['gameModule/getCurrentLevelLimit']
		},
		currentRoom() {
            return this.$store.state.gameModule.currentRoom
		},
        member() {
            return this.$store.state.gameModule.member
        },
        roomLevel() {
            return this.$store.state.gameModule.currentLevel
		},
		isBetClosed() {
            return this.currentRoom.state != 1
		},
		cardResult() {
			/*let testObj = [
				{
					cards: ['KH','TD','TC'],
					score: 2,
					type: 'player'
				},
				{
					cards: ['3S','4C','4C'],
					score: 1,
					type: 'banker'
				}
			]
			return testObj;*/
			// return this.currentRoom.card
			return this.computedCardRes(this.currentRoom.card)
		},

		gameResult() {
			let result = '';
			if (typeof(this.currentRoom.result) !== 'undefined' || this.currentRoom.result !== null) {
				switch(this.currentRoom.result) {
					case 'a': result = this.$t('game.result.banker'); break;
					case 'b': result = this.$t('game.result.bankerPlayerPair'); break;
					case 'c': result = this.$t('game.result.bankerBankerPair'); break;
					case 'd': result = this.$t('game.result.bankerBankerPlayerPair'); break;
					case 'e': result = this.$t('game.result.player'); break;
					case 'f': result = this.$t('game.result.playerPlayerPair'); break;
					case 'g': result = this.$t('game.result.playerBankerPair'); break;
					case 'h': result = this.$t('game.result.playerBankerPlayerPair'); break;
					case 'i': result = this.$t('game.result.tie'); break;
					case 'j': result = this.$t('game.result.tiePlayerPair'); break;
					case 'k': result = this.$t('game.result.tieBankerPair'); break;
					case 'l': result = this.$t('game.result.tieBankerPlayerPair'); break;
				}
			}
			return result;
		}
	},
    methods: {
				computedCardRes(cards) {

					try {
						cards[0].score = cards[0].score %10;
						cards[1].score = cards[1].score %10;
						return cards

					} catch(e) {
						return false
					}
				},
        playAudio(id) {
            if (localStorage.getItem('muteAudio') == 0) {
				var audioToPlay, canOverlap = false, filteredArray;
				filteredArray = this.audioFiles.filter(function(item){
                    return [id].indexOf(item.id) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        canOverlap = filteredArray[item].canOverlap;
                    });
				}
				this.audioFiles.forEach((key,item) => {
					if (this.audioFiles[item].canOverlap == false && !canOverlap) {
						this.audioFiles[item].file.pause();
					}
                    if (this.audioFiles[item].id == id && id != 'countdown' && id != 'chip') {
						this.audioFiles[item].file.pause();
						audioToPlay = this.audioFiles[item].file;
					} else if ((this.audioFiles[item].id == 'countdown' || this.audioFiles[item].id == 'countdown2') && id == 'countdown') {
						if (this.audioFiles[item].id == 'countdown') {
							if (this.audioFiles[item].isPlaying) {
								this.audioFiles[item].isPlaying = false;
							} else {
								audioToPlay = this.audioFiles[item].file;
								this.audioFiles[item].isPlaying = true;
							}
						} else {
							if (audioToPlay == null) {
								audioToPlay = this.audioFiles[item].file;
							}
						}
					} else if ((this.audioFiles[item].id == 'chip' || this.audioFiles[item].id == 'chip2') && id == 'chip') {
						if (this.audioFiles[item].id == 'chip') {
							if (this.audioFiles[item].isPlaying) {
								this.audioFiles[item].isPlaying = false;
							} else {
								audioToPlay = this.audioFiles[item].file;
								this.audioFiles[item].isPlaying = true;
							}
						} else {
							if (audioToPlay == null) {
								audioToPlay = this.audioFiles[item].file;
							}
						}
					}
				});
				audioToPlay.play();
            }
        },
    },
	watch: {
        cardResult: {
            handler(){
                if (this.currentRoom.state == 3) {
					this.hideCard = false;
					setTimeout( () => {
						this.hideCard = true;
                    }, 5000)
                }
            },
            deep: true
		}
	}
}
</script>

<style>

</style>
