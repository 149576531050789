<!--=========================================================================================
  File Name: GameHeader.vue
  Description: Game Header Component
==========================================================================================-->
<template>
  <b-row
    class="top-head"
  >
    <betting-history ref="modalName" />

    <b-col cols="4" md="10" sm="10">
      <div class="left-menu-div">
        <div class="right-menu-itms">
          <img
            src="/assets/images/gameroom/btn_screen1.png"
            class="img img-fluid top-icn-screen img-height-30 cursor-pointer"
            @click="enterFullScreen"
          />
        </div>
        <div class="right-menu-itms">
          <img
            src="/assets/images/gameroom/btn_history.png"
            class="img img-fluid top-icn-history img-height-30 cursor-pointer"
            @click="$refs.modalName.openModal()"
          />
        </div>
        <div class="right-menu-itms" v-if="muteAudio == 0">
          <img
            src="/assets/images/gameroom/btn_sound1.png"
            class="img img-fluid top-icn-sound img-height-30 cursor-pointer audio-on"
            @click="muteAudioFn()"
          />
        </div>
        <div class="right-menu-itms" v-if="muteAudio == 1">
          <img
            src="/assets/images/gameroom/btn_sound1.png"
            class="img img-fluid top-icn-sound img-height-30 cursor-pointer audio-off"
            @click="muteAudioFn()"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import BettingHistory from "@/views/common/pc/BettingHistory";
// import MoveLobby from '@/components/game/MoveLobby'
export default {
  name: "GameHeader",
  components: {
    BettingHistory,
  },
  mounted() {
    if (localStorage.getItem("muteAudio") === null) {
      localStorage.setItem("muteAudio", 0);
    }
    this.muteAudio = localStorage.getItem("muteAudio");
  },
  data() {
    return {
      show: false,
      langs: [
        {
          code: "ko-KR",
          label: "한국어",
          imgPath: "/assets/images/gamelobby/flags/korea.png",
        },
        {
          code: "en-US",
          label: "English",
          imgPath: "/assets/images/gamelobby/flags/usa.png",
        },
        {
          code: "ch-CHINA",
          label: "中文",
          imgPath: "/assets/images/gamelobby/flags/china.png",
        },
      ],
      muteAudio: 0,
    };
  },
  computed: {
    member() {
      return this.$store.state.gameModule.member;
    },
  },
  methods: {
    redirectToLobby() {
      this.$router.push(`/lobby`);
    },
    enterFullScreen() {
      let isInFullScreen =
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        document.mozFullScreen ||
        document.webkitIsFullScreen;

      if (isInFullScreen) this.cancelFullScreen();
      else this.requestFullScreen();

      return false;
    },
    cancelFullScreen() {
      let el = document.body;
      let requestMethod =
        el.cancelFullScreen ||
        el.webkitCancelFullScreen ||
        el.mozCancelFullScreen ||
        el.exitFullscreen ||
        el.webkitExitFullscreen;
      if (requestMethod) {
        // cancel full screen.
        requestMethod.call(el);
      }
    },
    requestFullScreen() {
      let el = document.body;
      let requestMethod =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;

      if (requestMethod) {
        // Native full screen.
        requestMethod.call(el);
      }
    },
    displayBetHistory() {
      this.show = !this.show;
    },
    openMoveRoom() {},
    muteAudioFn() {
      if (localStorage.getItem("muteAudio") === null || this.muteAudio == 0) {
        this.muteAudio = 1;
      } else {
        this.muteAudio = 0;
      }
    },
  },
  watch: {
    muteAudio(audioStatus) {
      localStorage.setItem("muteAudio", audioStatus);
    },
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}

.company-logo {
  height: 50px !important;
  /* width: 150%; */
  padding: 5px;
}

.left-menu-div {
  left: 0;
  position: absolute;
  bottom: 6px;
}

.right-menu-itms {
  float: left;
  padding-left: 3vw;
  padding-right: 0px;
  padding-top: 5px;
}

.img-height-30 {
  height: 3vh;
}

@media only screen and (min-width: 720px) and (orientation: landscape) {
  .game-header {
    top: -5px;
  }
}
</style>
