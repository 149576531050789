var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.playerCard != undefined && _vm.bankerCard != undefined)?_c('div',{staticClass:"game-result"},[(_vm.gameType != 'DT')?_c('span',{class:['side-label', 'left-label']},[_vm._v(_vm._s(_vm.$t("game.cardResult.leftPanelLabel")))]):_vm._e(),(_vm.gameType != 'DT')?_c('span',{class:['side-label', 'right-label']},[_vm._v(_vm._s(_vm.$t("game.cardResult.rightPanelLabel")))]):_vm._e(),(_vm.gameType == 'DT')?_c('span',{class:['side-label', 'left-label']},[_vm._v(_vm._s(_vm.$t("game.cardResult.leftPanelLabelDT")))]):_vm._e(),(_vm.gameType == 'DT')?_c('span',{class:['side-label', 'right-label']},[_vm._v(_vm._s(_vm.$t("game.cardResult.rightPanelLabelDT")))]):_vm._e(),_c('div'),_c('b-row',[_c('b-col',{staticClass:"left-card-result rm-padding",class:{ winner: _vm.playerCard.score > _vm.bankerCard.score && false }},[_c('b-row',{staticClass:"gm-cd-mrgin rm-padding"},[(_vm.playerCard.cards.length < 3)?_c('b-col',{staticClass:"card-data left-card-3 rm-padding",class:['tilted'],staticStyle:{"padding-right":"0px"}},[_c('span',{staticClass:"card-image"})]):_vm._e(),_vm._l((_vm.playerCard.cards.slice().reverse()),function(card,idx){return _c('b-col',{key:idx,staticClass:"card-data left-card-3  rm-padding",class:{
            tilted: idx == 0 && _vm.playerCard.cards.length == 3,
            'col-5': idx == 0 && _vm.playerCard.cards.length == 3,
          },staticStyle:{"padding-right":"0px"}},[_c('span',{staticClass:"card-image",class:_vm._f("cardStyle")(card)})])})],2)],1),_c('b-col',{staticClass:"win-result rm-padding",staticStyle:{"background-color":"lightgray"},attrs:{"cols":"2"}},[_c('b-row',{staticStyle:{"height":"100%"}},[_c('b-col',{class:[
            'left-win-result',
            { 'win-font': _vm.playerCard.score > _vm.bankerCard.score } ]},[_c('span',[_vm._v(_vm._s(_vm.playerCard.score))])]),_c('b-col',{class:[
            'right-win-result',
            { 'win-font': _vm.playerCard.score < _vm.bankerCard.score } ]},[_c('span',[_vm._v(_vm._s(_vm.bankerCard.score))])])],1)],1),_c('b-col',{staticClass:"right-card-result rm-padding",class:{ winner: _vm.bankerCard.score > _vm.playerCard.score && false }},[_c('b-row',{staticClass:"gm-cd-mrgin rm-padding"},[_vm._l((_vm.bankerCard.cards),function(card,idx){return _c('b-col',{key:idx,staticClass:"card-data left-card-3  rm-padding",class:{ tilted: idx == 2, 'col-5': idx == 2 },staticStyle:{"padding-left":"0px"}},[_c('span',{staticClass:"card-image",class:_vm._f("cardStyle")(card)})])}),(_vm.bankerCard.cards.length < 3)?_c('b-col',{staticClass:"card-data left-card-3  rm-padding",class:['tilted'],staticStyle:{"padding-right":"0px"}},[_c('span',{staticClass:"card-image"})]):_vm._e()],2)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }