<!--=========================================================================================
  File Name: GameResult.vue
  Description: Game Result Component
==========================================================================================-->
<template>
  <div class="game-result" v-if="playerCard != undefined && bankerCard != undefined">
    <span :class="['side-label', 'left-label']" v-if="gameType != 'DT'">{{
      $t("game.cardResult.leftPanelLabel")
    }}</span>
    <span :class="['side-label', 'right-label']" v-if="gameType != 'DT'">{{
      $t("game.cardResult.rightPanelLabel")
    }}</span>
    <span :class="['side-label', 'left-label']" v-if="gameType == 'DT'">{{
      $t("game.cardResult.leftPanelLabelDT")
    }}</span>
    <span :class="['side-label', 'right-label']" v-if="gameType == 'DT'">{{
      $t("game.cardResult.rightPanelLabelDT")
    }}</span>
    <div></div>
    <b-row>
      <!-- Player Cards -->
      <b-col
        class="left-card-result rm-padding"
        :class="{ winner: playerCard.score > bankerCard.score && false }"
      >
        <b-row class="gm-cd-mrgin rm-padding">
          <b-col
            class="card-data left-card-3 rm-padding"
            style="padding-right:0px"
            :class="['tilted']"
            v-if="playerCard.cards.length < 3"
          >
            <span class="card-image"></span>
          </b-col>
          <b-col
            class="card-data left-card-3  rm-padding"
            v-for="(card, idx) in playerCard.cards.slice().reverse()"
            :key="idx"
            style="padding-right:0px"
            :class="{
              tilted: idx == 0 && playerCard.cards.length == 3,
              'col-5': idx == 0 && playerCard.cards.length == 3,
            }"
          >
            <span class="card-image" :class="card | cardStyle"></span>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        class="win-result rm-padding"
        cols="2"
        style="background-color:lightgray;"
      >
        <b-row style="height:100%;">
          <!-- Player Score -->
          <b-col
            :class="[
              'left-win-result',
              { 'win-font': playerCard.score > bankerCard.score },
            ]"
          >
            <span>{{ playerCard.score }}</span>
          </b-col>
          <!-- Banker Score -->
          <b-col
            :class="[
              'right-win-result',
              { 'win-font': playerCard.score < bankerCard.score },
            ]"
          >
            <span>{{ bankerCard.score }}</span>
          </b-col>
        </b-row>
      </b-col>
      <!-- Banker Cards -->
      <b-col
        class="right-card-result rm-padding"
        :class="{ winner: bankerCard.score > playerCard.score && false }"
      >
        <b-row class="gm-cd-mrgin rm-padding">
          <b-col
            class="card-data left-card-3  rm-padding"
            v-for="(card, idx) in bankerCard.cards"
            :key="idx"
            style="padding-left:0px"
            :class="{ tilted: idx == 2, 'col-5': idx == 2 }"
          >
            <span class="card-image" :class="card | cardStyle"></span>
          </b-col>
          <b-col
            class="card-data left-card-3  rm-padding"
            style="padding-right:0px"
            :class="['tilted']"
            v-if="bankerCard.cards.length < 3"
          >
            <span class="card-image"></span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "GameResult",
  props: {
    playerCard: {
      required: false,
      type: Object,
    },
    bankerCard: {
      required: false,
      type: Object,
    },
    gameType: {
      required: true,
      type: String,
    },
  },
  // To keep for testing
  /*data() {
        return {
            playerCard: {
                cards: ['AD','9H'],
                score: 0
            },
            bankerCard: {
                cards: ['6D','8S'],
                score: 4
            }
        }
    },*/
  filters: {
    cardStyle: function(card) {
      var cardClass = "";
      if (card != null) {
        var cardNumber = card.slice(0, 1);
        if (card != "") {
          switch (cardNumber.toString()) {
            case "A":
              cardClass = "01";
              break;
            case "2":
              cardClass = "02";
              break;
            case "3":
              cardClass = "03";
              break;
            case "4":
              cardClass = "04";
              break;
            case "5":
              cardClass = "05";
              break;
            case "6":
              cardClass = "06";
              break;
            case "7":
              cardClass = "07";
              break;
            case "8":
              cardClass = "08";
              break;
            case "9":
              cardClass = "09";
              break;
            case "T":
              cardClass = "10";
              break;
            case "J":
              cardClass = "11";
              break;
            case "Q":
              cardClass = "12";
              break;
            case "K":
              cardClass = "13";
              break;
            case null:
              cardClass = "";
              break;
          }
          if (
            card.slice(0, 2) != "10" &&
            card.slice(0, 2) != "11" &&
            card.slice(0, 2) != "12" &&
            card.slice(0, 2) != "13" &&
            card.length == 3
          ) {
            cardClass = card.toLowerCase();
          } else if (card.slice(0, 2) != "10") {
            cardClass =
              card
                .slice(1, 2)
                .toString()
                .toLowerCase() + cardClass;
          } else {
            cardClass =
              card
                .slice(2, 3)
                .toString()
                .toLowerCase() + "10";
          }
        }
      }

      return cardClass;
    },
  },
  methods: {
    getResult(playerScore, bankerScore) {
      if (playerScore > bankerScore) {
        return "playerResult";
      } else if (bankerScore > playerScore) {
        return "bankerResult";
      } else {
        return "tieResult";
      }
    },
  },
};
</script>

<style scoped>
.side-label {
  position: absolute;
  display: inline-block;
  font-size: 1.7vw;
  height: 12vw;
  width: 15%;
  z-index: 10;
  top: 1vw;
  border-top: 0.2px solid transparent;
  border-bottom: 0.2px solid transparent;
}

.left-label {
  padding-left: 1vw;
  left: 0;
  text-align: left;
  background-image: linear-gradient(
    to left,
    rgba(255, 0, 0, 0),
    rgba(0, 0, 255, 1)
  );
  border-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
}

.right-label {
  padding-right: 1vw;
  right: 0;
  text-align: right;
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(255, 0, 0, 1)
  );
  border-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
}

.win-result {
  height: 9rem;
}

.left-card-result .tilted {
  transform: rotate(-90deg);
}

.right-card-result .tilted {
  transform: rotate(90deg);
}

.winner {
  border-radius: 0.3vw;
}

.left-card-result.winner {
  padding: 0.3vw;
  background-color: #00f;
  animation: playerwin 1s linear infinite;
}

.right-card-result.winner {
  padding: 0.3vw;
  background-color: #f00;
  animation: bankerwin 1s linear infinite;
}

.card-data {
  padding: 0;
}

.left-win-result {
  font-size: 8vw;
  font-weight: 700;
  margin-top: 10%;
  background: -webkit-linear-gradient(315deg, #29539b 0%, #2929ff 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.3vw black;
}

.right-win-result {
  font-size: 8vw;
  font-weight: 700;
  margin-top: 10%;
  background: -webkit-linear-gradient(315deg, #f00 0%, #f55 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.3vw black;
}

.win-font {
  margin-top: 0%;
  font-size: 14vw;
}

.hideCol {
  display: none;
}

@keyframes playerwin {
  50% {
    background-color: rgba(0, 0, 255, 0.5);
  }
}

@keyframes bankerwin {
  50% {
    background-color: rgba(255, 0, 0, 0.5);
  }
}

.gamescreen-page .game-result {
  width: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 255, 0.8),
    rgba(0, 0, 255, 0.6),
    rgba(255, 0, 0, 0),
    rgba(255, 0, 0, 0.6),
    rgba(255, 0, 0, 0.8)
  ) !important;
  border-radius: 0.3vw;
  display: inline-block;
  width: 100%;
  opacity: 1;
  text-align: center;
  font-size: 1vw;
  padding: 0.5vw;
  position: absolute;
  width: 100%;
  bottom: 2vh;
  /* padding: 0; */
  z-index: 25;
  height: 18vh;
}

.gamescreen-page .card-image {
  width: 9vw;
  height: 13vw;
  display: inline-block;
  background-size: cover;
}

.left-card-result .tilted .card-image {
  margin-right: 0vw;
  margin-top: 0vw;
}

.side-label {
  position: absolute;
  display: inline-block;
  font-size: 5em;
  height: 8vw;
  width: 10%;
  z-index: 10;
  top: 0.5vw;
  border-top: 0.2px solid transparent;
  border-bottom: 0.2px solid transparent;
}
.gm-cd-mrgin {
  margin-top: 6vh;
}

@media screen and (min-width: 812px) and (orientation: landscape) {
  .gamescreen-page .game-result {
    height: 20vh;
  }
  .gamescreen-page .card-image {
    width: 5vw;
    height: 7.5vw;
  }
  .left-card-result .tilted .card-image {
    margin-right: -8vw;
    margin-top: 8vw;
  }
  .right-card-result .tilted .card-image {
    margin-right: 0vw;
    margin-bottom: -10vw;
  }
  .left-win-result {
    font-size: 3vw;
  }
  .right-win-result {
    font-size: 3vw;
  }
  .win-font {
    font-size: 5vw;
  }
  .gm-cd-mrgin {
    margin-top: 0vh;
  }
  .side-label {
    font-size: 3em;
    height: 4vw;
    width: 10%;
  }
  .win-result {
    height: 4rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: landscape) {
  .gamescreen-page .game-result {
    height: 18vh;
  }
  .gamescreen-page .card-image {
    width: 5vw;
    height: 7.5vw;
  }
  .left-card-result .tilted .card-image {
    margin-right: -8vw;
    margin-top: 8vw;
  }
  .right-card-result .tilted .card-image {
    margin-right: 0vw;
    margin-bottom: -10vw;
  }
  .left-win-result {
    font-size: 4vw;
    margin-top: 20%;
  }
  .right-win-result {
    font-size: 4vw;
    margin-top: 20%;
  }
  .win-font {
    font-size: 6vw;
    margin-top: 10%;
  }
  .gm-cd-mrgin {
    margin-top: 3vh;
  }
  .side-label {
    font-size: 3em;
    height: 4vw;
    width: 10%;
  }
  .win-result {
    height: 8rem;
  }

  .gamescreen-page .card-image {
    width: 7vw;
    height: 10vw;
  }
}

@media screen and (min-width: 1366px) and (orientation: landscape) {
  .win-result[data-v-36fb042b] {
    height: 11rem;
  }
}

@media screen and (min-width: 768px) and (orientation: portrait) {
  .win-result {
    height: 11rem;
  }
  .left-win-result {
    font-size: 5vw;
    margin-top: 65%;
  }
  .right-win-result {
    font-size: 5vw;
    margin-top: 65%;
  }
  .win-font {
    margin-top: 50%;
    font-size: 8vw;
  }
}

@media screen and (min-width: 1024px) and (orientation: portrait) {
  .win-result {
    height: 15rem;
  }
}
</style>
